import { RecordTuple } from "record-tuple";
import {
  AnyMessage,
  MethodInfoUnary,
  PartialMessage,
  PlainMessage,
  ServiceType,
} from "@bufbuild/protobuf";
import {
  createPromiseClient,
  PromiseClient,
  Transport,
} from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";

import Case from "@repo/utils/Case";
import Log from "@repo/utils/Log";
import UArray from "@repo/utils/UArray";
import UFunction from "@repo/utils/UFunction";
import UPromise from "@repo/utils/UPromise";
import UString from "@repo/utils/UString";
import UType from "@repo/utils/UType";

import { Error as CanadaErrorPb } from "../../gen/kikoff_canada/protobuf/types/error_pb";

import Stub from "./Stub";

export class RpcError extends Error {
  name = "RpcError";

  issues: UArray.WithLengthOf.AtLeast<RpcError.Issue[], 1>;

  constructor(issues: RpcError.Issue[], options?: ErrorOptions) {
    if (!UArray.hasLengthOf.atLeast(issues, 1))
      throw new Error("Attempted to create RpcError with 0 issues.");

    super(issues[0].message, options);
    this.issues = issues;
  }
}
export namespace RpcError {
  export type Issue = {
    code: keyof typeof CanadaErrorPb.Code;
    message: string;
  };
}

export class CanadaRpcError extends RpcError {
  constructor(protoErrors: CanadaErrorPb[], options?: ErrorOptions) {
    super(
      protoErrors.flatMap(({ message, code }) => {
        if (code === CanadaErrorPb.Code.NEEDS_REFRESH) window.location.reload();

        return {
          message,
          code: CanadaErrorPb.Code[code] as keyof typeof CanadaErrorPb.Code,
        };
      }),
      options,
    );
  }
}

const decoder = new TextDecoder();

namespace Rpc {
  export type Cache = Record<
    string,
    { spec: ServiceType; client: PromiseClient<any> }
  >;

  export function createTransport({
    namespace,
    basePath = "",
    serviceCache = {},
  }: {
    namespace: string;
    basePath?: string;
    serviceCache: Cache;
  }) {
    const pendingCache = UPromise.PendingCache.create<any, Response>();

    return createConnectTransport({
      baseUrl: "",
      credentials: "include",
      fetch: (url, options) => {
        if (typeof url !== "string")
          throw new Error("Unexpected non-string URL.");

        const [serviceName, methodName] = UArray.assertLengthOf(
          url.slice(`/${namespace}`.length).split("/", 2),
          2,
        );

        url = `${import.meta.env.API_ORIGIN}${basePath}/${Case.fromCamel(UString.beforeSuffix(serviceName, "Service")).toKebab()}/${Case.fromCamel(methodName).toKebab()}`;

        const { I, O } =
          serviceCache[serviceName]!.spec.methods[
            UString.decapitalize(methodName)
          ]!;

        const payload = JSON.parse(
          decoder.decode(options!.body! as Uint8Array),
        );
        const ref = RecordTuple.deep([url, payload] as const);

        return pendingCache.use(ref, () =>
          fetch(url, {
            ...options,
            body: I.fromJson(payload).toBinary(),
            headers: {
              Accept: "application/x-protobuf",
              "Content-Type": "application/x-protobuf",
              "Cache-Control": "no-cache",
            },
          }).then((response) => {
            if (response.status !== 200)
              throw new Error(
                "Something went wrong, please refresh or try again later.",
                {
                  cause: new Error(
                    `Responded with non-200 status code: ${response.status} (${url})`,
                  ),
                },
              );

            response.json = UFunction.memo(async () =>
              O.fromBinary(
                new Uint8Array(await response.arrayBuffer()),
              ).toJson(),
            );
            return response;
          }),
        );
      },
    });
  }

  const hasErrors = <M extends PlainMessage<AnyMessage>>(
    message: M,
  ): message is M & { errors: CanadaErrorPb[] } =>
    message?.errors[0] instanceof CanadaErrorPb;

  export const createServicesClient = <
    ServiceMap extends Record<
      string,
      () => Promise<Record<string, ServiceType>>
    >,
  >(
    serviceMap: ServiceMap,
    {
      serviceCache,
      transport,
      stubs,
    }: {
      serviceCache: Cache;
      transport: Transport;
      stubs?: () => Promise<
        Record<
          string,
          {
            enabled?: boolean;
            default: Stub.Schema<Record<string, () => Promise<any>>>;
          }
        >
      >;
    },
  ) =>
    new Proxy(
      {},
      {
        get(_, shortServiceName: string) {
          const serviceName = `${shortServiceName}Service`;
          return new Proxy(
            {},
            {
              get:
                (_, methodName: string) => async (req: any, options: any) => {
                  if (!serviceCache[serviceName]) {
                    const spec = (await (serviceMap as any)[serviceName]())[
                      serviceName
                    ];
                    serviceCache[serviceName] = {
                      spec,
                      client: createPromiseClient(spec, transport),
                    };
                  }

                  const { client, spec } = serviceCache[serviceName]!;
                  let isStubbed = false;
                  const withStubsIfAny = (async function next(
                    [current, ...rest],
                    req,
                  ): Promise<PlainMessage<AnyMessage>> {
                    if (!current)
                      return client[methodName]!(
                        req,
                        options,
                      ) as Promise<AnyMessage>;

                    const stub =
                      current.enabled &&
                      current.default[
                        shortServiceName as keyof typeof current.default
                      ]?.[methodName];
                    if (!stub) return next(rest, req);

                    if (!isStubbed) {
                      isStubbed = true;
                      // Add default latency to test loading states
                      await UPromise.delay(500);
                    }

                    return new spec.methods[methodName]!.O(
                      await stub({
                        req,
                        makeRequest: (stubRequest = req) =>
                          next(rest, stubRequest),
                      }),
                    );
                  })(Object.values((await stubs?.()) || {}), req);

                  Log.withTrace`${Log.color("grey", "rpc")} ${Log.fn(
                    [
                      Log.color("#faf", "canadaRpc"),
                      shortServiceName,
                      methodName,
                    ],
                    [
                      new spec.methods[methodName]!.I(req).toJson({
                        enumAsInteger: false,
                      }),
                    ],
                  )}: ${
                    isStubbed
                      ? Log.join(
                          [
                            Log.style(
                              "color:white;background:red",
                              "[STUBBED]",
                            ),
                            " ",
                          ],
                          "",
                        )
                      : ""
                  }${withStubsIfAny.then((res) =>
                    res.toJson({ enumAsInteger: false }),
                  )}`;

                  return withStubsIfAny.then((res) => {
                    if (hasErrors(res)) throw new CanadaRpcError(res.errors);
                    return res;
                  });
                },
            },
          );
        },
      },
    ) as {
      [ServiceName in keyof typeof serviceMap as UString.BeforeSuffix<
        UType.Narrow<ServiceName, string>,
        "Service"
      >]: UType.Narrow<
        Awaited<ReturnType<(typeof serviceMap)[ServiceName]>>,
        Record<ServiceName, ServiceType>
      >[ServiceName]["methods"] extends infer Service
        ? {
            [MethodName in keyof Service]: Service[MethodName] extends MethodInfoUnary<
              infer I,
              infer O
            >
              ? (request: PartialMessage<I>) => Promise<
                  // Errors will always be thrown
                  Omit<O, "errors">
                >
              : never;
          }
        : never;
    };
}

export default Rpc;
