import "core-js/stable/structured-clone";

import {
  createComputed,
  createContext,
  FlowProps,
  getOwner,
  JSX,
  onMount,
  runWithOwner,
  untrack,
  useContext,
} from "solid-js";
import { createStore } from "solid-js/store";
import { RecordTuple } from "record-tuple";

import Log from "@repo/utils/Log";
import UBoolean from "@repo/utils/UBoolean";
import UFunction from "@repo/utils/UFunction";
import UObject from "@repo/utils/UObject";
import UProxy from "@repo/utils/UProxy";
import UType from "@repo/utils/UType";
import Platform from "@repo/utils-client/Platform";

import USolid from "./USolid";

type FeatureStore<Context extends FeatureStore.Setup.Context> = [
  Context["accessors"],
  Context["actions"],
] & {
  mutations?: Context["mutations"];
};

namespace FeatureStore {
  const config = {
    useCaptureError:
      () =>
      (error: unknown): void => {
        throw error;
      },
  };

  export function configure(c: Partial<typeof config>) {
    Object.assign(config, c);
  }

  export namespace Setup {
    type _Accessors = UFunction.Any | { [key: string]: _Accessors };

    export type Context = {
      accessors: _Accessors;
      mutations: UType.Nested<
        Record<string, UFunction.Any.Void | UType.Nested.Children>
      >;
      actions: UType.Nested<
        Record<string, UFunction.Any | UType.Nested.Children>
      >;
      state: UObject.Any;
    };
  }

  export type Accessors<Store extends UFunction.Maybe<[], FeatureStore<any>>> =
    UFunction.Unwrap<Store>[0];

  export function init<
    Name extends string,
    State extends UObject.Any,
    Accessors extends Setup.Context["accessors"],
    Actions extends Setup.Context["actions"],
  >(
    name: Name,
    initialState: State,
    setup: (
      storePair: ReturnType<typeof createStore<State>>,
      context: {
        attachAction: <Accessor extends UFunction.Any>(
          accessor: Accessor,
          action: (...args: Parameters<Accessor>) => any,
          checkLoaded?: (
            ...args: Parameters<Accessor>
          ) => (result: ReturnType<Accessor>) => any,
        ) => Accessor;
        withLogger: <T extends UObject.Any>(label: string, subject: T) => T;
      },
    ) => { accessors: Accessors; actions: Actions },
  ) {
    const Context = createContext<ReturnType<typeof createStore<State>>>();

    return {
      Provider(props) {
        const [store, setStore] = createStore(structuredClone(initialState));

        createComputed((prev) => {
          if (!Platform.isClient) return;

          const current = JSON.parse(JSON.stringify(store));
          Log.group`${Log.color("grey", "new state")} ${Log.color("orange", name)}`(
            () => {
              Log.plain(current);
              Log.group`Previous state`(() => {
                Log.plain(prev);
              });
            },
          );

          return current;
        });

        return (
          <Context.Provider value={[store, setStore]}>
            {props.children}
          </Context.Provider>
        );
      },
      [`use${name}`]() {
        const context = useContext(Context);

        if (!context)
          throw new Error(
            `use${name} must be called in child of ${name}Provider.`,
          );

        const withLogger = (label: string, subject: any) =>
          Log.enabled
            ? (UProxy.attachDeepHooks(subject, (location, path, { args }) => {
                if (location !== "before") return;
                const lastArg = args.at(-1);
                if (path.length === 0 && typeof lastArg === "function")
                  args[args.length - 1] = (state: any) => {
                    const res = lastArg(state);
                    log(res);
                    return res;
                  };
                else log();

                function log(lastArg = args.at(-1)) {
                  Log.withTrace`${Log.color("grey", label)} ${Log.fn(
                    [`set${name}`, ...path],
                    [
                      ...args.slice(0, -1),
                      ...(args.length > 0 ? [lastArg] : []),
                    ],
                  )}`;
                }
              }) as typeof subject)
            : subject;

        const owner = getOwner();

        const extensions = UFunction.memo(
          () =>
            runWithOwner(owner, () => {
              const [store, setStore] = useContext(Context)!;
              return setup([store, withLogger("set", setStore)], {
                attachAction: (
                  accessor,
                  action,
                  checkLoaded = () => UBoolean.isTruthy,
                ) =>
                  ((...args: Parameters<typeof accessor>) => {
                    const res = USolid.runMemo(() =>
                      accessor(...(args as any[])),
                    );

                    // Capture error in as narrow of a context as possible,
                    // still want to use outer owner to run actions once.
                    const errorOwner = getOwner();

                    if (untrack(() => !checkLoaded(...args)(res)))
                      USolid.Owner.runOnce(
                        owner,
                        RecordTuple.deep([accessor, ...args]),
                        () => {
                          onMount(() => {
                            runWithOwner(errorOwner || owner, async () => {
                              const captureError = config.useCaptureError();
                              try {
                                await action(...args);
                              } catch (e) {
                                captureError(e);
                              }
                            });
                          });
                        },
                      );

                    return res;
                  }) as typeof accessor,
                withLogger,
              });
            })!,
        );

        return [
          UProxy.lazyFns(() => extensions().accessors),
          UProxy.lazyFns(() => extensions().actions),
        ];
      },
    } as {
      [Key in `use${Name}`]: () => [Accessors, Actions];
    } & {
      Provider(props: FlowProps): JSX.Element;
    };
  }
}

export default FeatureStore;
